import React, {useEffect} from "react";
import '../resources/styles/pages/_404.scss'


import Layout from "../components/layout"
import SEO from "../components/seo"
import bg from "../resources/images/404.svg"
import CodicateImage from "../components/codicate-image";

import lottie from "lottie-web";
import animation from "../resources/animations/404"
import TransitionButton from "../components/transition-button"

let animationContainer = React.createRef();

const NotFoundPage = () => {
    useEffect(() => {
        lottie.loadAnimation({
            container: animationContainer.current,
            animationData: animation
        })
    })
return (
        <Layout className="page-404">
            <SEO title="404: Not found"/>
            <div className="mainText">
                <strong>four uh-oh four…</strong>
                This page isn’t here, please do not despair
                <TransitionButton aria-label="go to homepage"
                                  pageBackground="toBlueBg"
                              to='/'
                              >Go back to home page</TransitionButton>
            </div>
            <div className="animation-container" ref={animationContainer} />
            <CodicateImage img={bg} alt="404" className="bg404"/>

        </Layout>
    )
}

export default NotFoundPage
